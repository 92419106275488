.amenity {
  text-align: center;
  margin-bottom: 20px;
}

.distance {
  font-size: 30px;
}

.name {
  margin-top: 10px;
  color: white;
}

.separator {
  width: 50%;
  border: 1px solid white;
  margin: 10px auto;
}
