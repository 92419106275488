.rera-background-container {
  padding-top: 70px;
  padding-bottom: 75px;
  /* background-image: url("../WorkFromForest/WorkFromForestImageDesktop.png"); */
  background-size: cover;
  background-position: center;
  text-align: center;

  /* height: 100vh; */
}

.rera-qr-container {
  max-width: 400px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: -10px;
  /* height: 100%; */
  justify-items: center;
  margin: auto;
  margin-top: 100px;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
  /* background-image: url("./P52100054087.png");
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 100px; */
}
.div2 {
  grid-area: 2 / 1 / 3 / 2;
}
.div3 {
  grid-area: 1 / 2 / 2 / 3;
  /* background-image: url("./P52100054087.png"); */
}
.div4 {
  grid-area: 2 / 2 / 3 / 3;
}

.rera-h1 {
}
.rera-h2 {
  /* font-size: larger; */
}
.rera-p {
  font-size: large;
  padding: 20px 250px;
}

@media (max-width: 768px) {
  .rera-background-container {
    padding-top: 70px;
    padding-bottom: 75px;
    /* background-image: url("../WorkFromForest/WorkFromForestMobile.png"); */
    background-size: cover;
    background-position: center;
    /* height: 100vh; */
  }

  .rera-p {
    font-size: large;
    padding: 20px;
  }
}
