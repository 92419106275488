.community-container {
  color: white;
  line-height: 2;
  font-size: 25px;
}

.textOverlay {
  margin-top: 10%;
}
.manTextOverlay {
  display: block;
  float: left;
  margin-left: 5%;
  /* font-size: 15px; */
  text-align: justify;
  color: white;
}
.manImage {
  margin-top: -50%;
}

@media (min-width: 768px) {
  .mobileView {
    display: none;
  }
  .manImage {
    margin-top: -19%;
  }
  .manTextOverlay {
    display: block;
    float: left;
    margin-left: 10%;
    font-size: 20px;
    text-align: justify;
    color: white;
    top: 10%;
    margin-top: 10%;
  }
}
@media (max-width: 768px) {
  .manTextOverlay {
    display: block;
    /* float: left; */
    /* margin-left: 10%; */
    font-size: 15px;
    text-align: center;
    color: white;
  }
  .desktopView {
    display: none;
  }
}
