

.whtsAp{
    position: fixed;
    bottom: 20px;
    right: 30px;
    width: 50px;
    height: 50px;
}

.whatsapp-container{
    cursor: pointer;
}

