.managed-forest-container {
  color: white;
  line-height: 2;
}

.cartextOverlay {
  padding-top: 350px;
  padding-left: 20px;
}
.carTextOverlay {
  display: block;
  /* float: left; */
  padding-top: 50px;
  margin-left: 5%;
  font-size: 25px;
  text-align: justify;
  color: white;
}
.carImage {
  /* margin-top: -100%; */
  /* position: absolute; */
  top: 0%;
}

@media (min-width: 768px) {
  .mobileView {
    display: none;
  }
  .carImage {
    /* margin-top: -100%; */
  }
  .carTextOverlay {
    display: block;
    /* float: left; */
    /* margin-left: 10%; */
    font-size: 20px;
    text-align: center;
    color: white;
    top: 10%;
    /* margin-top: 10%; */
  }
}
@media (max-width: 768px) {
  .carTextOverlay {
    display: block;
    /* float: left; */
    margin: 10%;
    font-size: 20px;
    text-align: center;
    color: white;
    padding-top: 20px;
  }
  .desktopView {
    display: none;
  }
}
