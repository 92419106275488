.amenity {
  text-align: center;
  margin-bottom: 20px;
}

.circle {
  /* width: 80px;
  height: 80px; */
  /* background-color: brown; */
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.circle img {
  width: 70px;
  height: 70px;
}

.name {
  margin-top: 10px;
  color: white;
}

.separator {
  width: 50%;
  border: 1px solid white;
  margin: 10px auto;
}
