.bookasitevisit {
  width: 25%;
  margin-left: 60%;
  text-transform: uppercase;
  padding: 7px;
  background-color: yellow;
}
.image-container {
  position: relative;
  display: inline-block;
  background-image: url("./hero-background.PNG");
}

.offerBanner{
  overflow: hidden;
  margin-top: -90px;
}

.offerBanner img{
  position: relative;
  width: 100%;
  height: auto;

}

.text-overlay {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  font-size: 20px;
  color: black;
}
.text-overlay1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  /* font-size: 20px; */
  color: black;
}
.text-overlay2 {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  /* padding: 10px; */
  font-size: 5rem;
  color: black;
}
/* .bannerData{
    top: -20%;
} */
.greenGradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(135deg, #1d3306, #3a5936e3);
}
.text-overlay-overview {
  position: absolute;

  left: 30%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  /* padding: 10px; */
  font-size: 20px;
  margin-top: 300px;
  color: white;
  text-align: justify;
}
.grid-container {
  /* display: grid;
    grid-template-columns: auto auto auto;
    background-color: #d19360;
    border: 1px solid white;
    padding: 10px;
    margin-top: -7%;
    position: relative */
  position: relative;
  top: 60%;
  left: 50%;
  display: grid;
  border: 1px solid white;
  grid-template-columns: auto auto;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  /* padding: 10px; */
  font-size: 20px;
  padding: 10px;
  color: black;
}
.grid-item {
  background-color: #d19360;
  padding: 20px;
  /* font-size: 30px; */
  border-right: 1px solid white;
  text-align: center;
}
.grid-item1 {
  background-color: #d19360;
  padding: 20px;
  /* font-size: 30px; */
  text-align: center;
}
.OverviewImage {
  margin-top: 100%;
}
.text-overlay-overview {
  position: absolute;

  left: 30%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  /* padding: 10px; */
  font-size: 20px;
  margin-top: 300px;
  color: white;
  text-align: justify;
}

.hinjawadiImage {
  margin-top: -100px;
  position: relative;
  z-index: -1;
}
.imgD {
  padding-right: calc(var(--bs-gutter-x) * 0) !important;
  padding-left: calc(var(--bs-gutter-x) * 0) !important;
  text-align: center;
}
@media (max-width: 768px) {
  .OverviewImageMob {
    text-align: center;
  }
  .text-overlay-overviewMob {
    padding: 20px;
    padding-top: 200px;
    /* width: 80%; */
    text-align: center;
  }
  .imageBg {
  }
  .image-container {
    display: none;
  }
  /* .respImage{
    margin-top: -100px;
  } */
  .bnrContainer {
    border: 2px solid black;
  }
  .newContainer {
  }
  .hideGrid {
    display: none;
  }

  .text-overlayMob {
    position: absolute;
    /* top: 80%; */
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(255, 255, 255, 0.7); */
    padding: 10px;
    font-size: 20px;
    /* background-color: white; */
    background: url("./hero-background.jpg");
    background-position: center;
    background-size: cover;
    border-radius: 20px;
    margin-top: 100px;
    color: black;
    width: 80%;
  }

  .imgD {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
  }
}
@media (max-width: 375px) {
  /* .respImage{
    margin-top: -100px;
  } */
  .text-overlay-overviewMob {
  }
  .imageBg {
  }
  .OverviewImageMob {
    /* margin-top: 20%; */
  }
  .bnrContainer {
    border: 2px solid black;
  }

  .newContainer {
  }
  .hideGrid {
    display: none;
  }
  .text-overlayMob {
    position: absolute;
    /* top: 60%; */
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(255, 255, 255, 0.7); */
    padding: 10px;
    font-size: 20px;
    background-color: white;
    border-radius: 20px;
    margin-top: 50px;
    color: black;
    width: 80%;
  }
  .grid-container {
    /* display: grid;
    grid-template-columns: auto auto auto;
    background-color: #d19360;
    border: 1px solid white;
    padding: 10px;
    margin-top: -7%;
    position: relative */
    position: relative;
    /* top: 60%;
    left: 50%; */
    display: grid;
    border: 1px solid white;
    grid-template-columns: auto auto;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(255, 255, 255, 0.7);
    /* padding: 10px; */
    font-size: 20px;
    padding: 10px;
    color: black;
    height: 70px;
  }
  .grid-item {
    background-color: #d19360;
    padding: 20px;
    /* font-size: 30px; */
    border-right: 1px solid white;
    text-align: center;
  }
  .imgD {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
  }
  .text-overlay-overview {
    margin-top: "50px";
  }
  .text-overlayMob {
    top: 80%;
  }
}
@media (max-width: 375px) {
  /* .respImage{
    margin-top: -100px;
  } */
  .text-overlay-overviewMob {
  }
  .imageBg {
  }
  .OverviewImageMob {
  }
  .bnrContainer {
    border: 2px solid black;
  }
  .OverviewImage {
    display: none;
  }
  .newContainer {
  }
  /* .hideGrid{
    display: none;
  } */
  .text-overlayMob {
    position: absolute;
    /* top: 60%; */
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgba(255, 255, 255, 0.7); */
    padding: 10px;
    font-size: 20px;
    background: url("./hero-background.jpg");
    border-radius: 20px;
    margin-top: 50px;
    color: black;
    width: 80%;
    margin-top: 50%;
  }
  .grid-container {
    /* display: grid;
    grid-template-columns: auto auto auto;
    background-color: #d19360;
    border: 1px solid white;
    padding: 10px;
    margin-top: -7%;
    position: relative */
    position: relative;
    /* top: 60%;
    left: 50%; */
    display: grid;
    border: 1px solid white;
    grid-template-columns: auto auto;
    /* transform: translate(-50%, -50%); */
    background-color: rgba(255, 255, 255, 0.7);
    /* padding: 10px; */
    font-size: 20px;
    padding: 10px;
    color: black;
    height: 70px;
  }
  .grid-item {
    background-color: #d19360;
    padding: 20px;
    /* font-size: 30px; */
    border-right: 1px solid white;
    text-align: center;
  }
  .imgD {
    padding-right: calc(var(--bs-gutter-x) * 0);
    padding-left: calc(var(--bs-gutter-x) * 0);
  }
  .text-overlay-overview {
    margin-top: "50px";
    display: none;
  }
  .text-overlayMob {
    margin-top: 50%;
  }
}
.container1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* gap: 20px; */
}
.partition1 {
  padding: 20px;
  box-sizing: border-box;
  font-size: 16px;
}

@media (max-width: 600px) {
  .OverviewImageMob {
    /* margin-top: 55%; */
  }
  .text-overlay-overviewMob {
  }
  .imageBg {
    margin-top: 100%;
  }
  .container1 {
    grid-template-columns: 1fr 1fr;
    background-color: #d19360;
  }
  .text-overlay-overview {
    top: 100%;
    right: -15%;
    font-size: 13px;
    margin-left: 25px;
    display: none;
  }
  .partition {
    padding: 10px;
    font-size: 14px;
  }
  .text-overlayMob {
    /* top: 65%; */
  }
  .hideGrid {
    display: none;
  }
}
/* .hideGrid{
  display: none;
} */

@media (min-width: 992px) {
  .OverviewImageMob {
  }
  .text-overlay-overviewMob {
  }
  .imageBg {
    display: none;
  }
  .hide1 {
    display: none;
  }
  .imgH {
    display: none;
  }
  .OverviewImage {
    margin-top: -12%;
  }
  .text-overlay-overview {
    margin-left: 7%;
  }
}

.grid-container {
  /* display: grid;
  grid-template-columns: auto auto auto;
  background-color: #d19360;
  border: 1px solid white;
  padding: 10px;
  margin-top: -7%;
  position: relative */
  position: relative;
  top: 60%;
  left: 50%;
  display: grid;
  border: 1px solid white;
  grid-template-columns: auto auto;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.7);
  /* padding: 10px; */
  font-size: 20px;
  padding: 10px;
  color: black;
}
.grid-item {
  background-color: #d19360;
  padding: 20px;
  /* font-size: 30px; */
  border-right: 1px solid white;
  text-align: center;
}
.grid-item1 {
  background-color: #d19360;
  padding: 20px;
  /* font-size: 30px; */
  text-align: center;
}

@media (max-width: 768px) {
  .OverviewImageMob {
  }
  .text-overlay-overviewMob {
  }
  .imageBg {
  }
  .container1 {
    grid-template-columns: 1fr 1fr;
    background-color: #d19360;
  }
  .text-overlayMob {
    margin-top: 60%;
  }
  .text-overlay-overview {
    display: none;
  }
  /* .btn01 {
    display: none;
  } */
  .OverviewImage {
    display: none;
  }
  /* .hideGrid{
    display: none;
  } */
}
/* @media(min-width: 430px) {
  .container1 {
    grid-template-columns: 1fr 1fr;
    background-color: #d19360;

  }
  .text-overlayMob{
    top: 70%;
  }
  .btn01 {
    display: none;
  }
} */

@media (min-width: 414px) {
  .OverviewImageMob {
    /* margin-top: 20%; */
  }
  .imageBg {
  }
  .text-overlayMob {
    top: 55%;
  }
}

.text-overlay,
.text-overlay2 {
  background: transparent;
}
