.background-container {
  padding-top: 70px;
  padding-bottom: 75px;
  background-image: url("../Contact/Contact\ Us\ BG\ Image.png");
  background-size: cover;
  background-position: center;
  color: #fff;
}

.form-container {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  padding: 30px;
  background-color: #455F3D;
  border-radius: 15px;
}

.form-container label {
  display: block;
  margin-bottom: 5px;
  color: #fff;
  text-align: left;
  font-size: 16px;
}

.contact-number a{
    text-decoration: none;
    color: #fff;
}

.contact-number a:hover{
    color: #D19360;
}

.form-container input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-container button {
  width: 50%;
  padding: 9px 15px;
  margin-top: 12px;
  background-color: #D19360;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}

.form-container button:hover {
  background-color: #fff;
  color: #455F3D;
}

.contact-p {
  padding: 20px;
}

.contact-a{
    color:#000000;
}
.error{
    color: #ed0911;
}
