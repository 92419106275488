.telepresenceGradient {
  background: rgb(2, 0, 36);
  background: linear-gradient(180deg, #1d3306, #3a5936e3);
}

.telepre-background-container {
  padding-top: 70px;
  /* padding-bottom: 75px; */
  /* background-image: url("../WorkFromForest/WorkFromForestImageDesktop.png"); */
  /* background-image: url("../TelepresenceRoom/TelepresenceRoomImage.png"); */
  /* background-size: contain; */
  /* background-position: right; */
  /* background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  min-height: 1500px;
  max-width: 100%; */
  color: white;
}

.telepre-h1 {
}
.telepre-h2 {
  /* font-size: larger; */
  padding: 30px;
}
.telepre-p {
  font-size: large;
  padding: 30px;
  padding-top: 10px;
}

.telepresence-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .telepre-background-container {
    padding-top: 70px;
    /* padding-bottom: 75px; */
    /* background-image: url("../WorkFromForest/WorkFromForestMobile.png"); */
    /* background-image: url("../WorkFromForest/WorkFromForest.png"); */
    /* background-image: url("../TelepresenceRoom/TelepresenceRoomImage.png"); */

    /* background-size: cover;
    background-position: center;
    height: 100vh; */
    /* background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    height: 100vh;
    min-height: 1100px;
    max-width: 100%; */
    color: white;
  }
}
