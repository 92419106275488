.footer {
  background-color: #333;
  color: #fff;
  padding: 30px 20px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.footer-address,
.footer-contact,
.footer-text {
  flex: 1 1 100%;
  margin-bottom: 20px;
  font-size: 15px;
}

/*.footer-address, .footer-contact{*/
/*    text-align: left;*/
/*}*/

.footer h3 {
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.footer p {
  margin: 0;
  line-height: 1.5;
}

.social{
    /*position: relative;*/
    /*display: block;*/
    margin: 20px;
}

.social a{
    color: #fff;
    padding: 20px;
}

.footer-contact p a{
    text-decoration: none;
    color: #fff;
}

.footer-contact p a:hover{
    color: #d19360;
}

.social a:hover{
    color:#D19360;
}

@media (min-width: 768px) {
  .footer-content {
    flex-wrap: nowrap;
  }

  .footer-address,
  .footer-contact,
  .footer-text {
    flex: 1 1 auto;
    margin-bottom: 0;
  }
  .social{
      margin: 0;
  }
}
