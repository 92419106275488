.why-hinjawadi-intro {
  color: white;
  padding-bottom: 25px;
}

.imgD {
  padding-right: calc(var(--bs-gutter-x) * 0);
  padding-left: calc(var(--bs-gutter-x) * 0);
}
.dropdown {
  /* width: 300px; */
  width: 50%;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.dropdown-header {
  padding: 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-body {
  padding: 5px;
  border-top: 1px solid #e5e8ec;
  display: none;
}

.dropdown-body.open {
  display: block;
}

.dropdown-item {
  padding: 10px;
}

.dropdown-item:hover {
  cursor: pointer;
}

.dropdown-item-dot {
  opacity: 0;
  color: #91a5be;
  transition: all 0.2s ease-in-out;
}

.dropdown-item-dot.selected {
  opacity: 1;
}

.icon {
  font-size: 13px;
  color: #91a5be;
  transform: rotate(0deg);
  transition: all 0.2s ease-in-out;
}

.icon.open {
  transform: rotate(90deg);
}

.hinjawadiOverlap {
  margin-top: -90%;
  font-size: 22.5px;
}
@media (min-width: 768px) {
  .hinjawadiOverlap {
    margin-top: 5%;
    position: absolute;
    z-index: 1;
    margin-left: 50px;
  }
  .dropdown {
    /* width: 300px; */
    width: 50%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  .mobileHinjawadi {
    display: none;
  }
  .hideMobile {
    display: none;
  }
}
@media (max-width: 768px) {
  .hideM {
    display: none;
  }
  .hideDrp {
    display: none;
  }
  .dropdown {
    width: 90%;
    border-radius: 10px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    background-color: white;
  }
  .hinjawadiOverlap {
    margin-top: 10%;
    z-index: 1;
    position: absolute;
  }
}
