.background-container {
  padding-top: 70px;
  padding-bottom: 75px;
  background-image: url("../Location/Location.png");
  background-size: cover;
  background-position: center;
  text-align: "center";
}

.amenities-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.amenities-grid-item {
  background-color: transparent;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.amenities-grid-item:hover {
  transform: translateY(-5px);
  transition: transform 0.3s ease;
}

.amenities-grid-item img {
  display: block;
  margin: 0 auto 10px;
  width: 100px;
  height: 100px;
}

.amenities-grid-item p {
  text-align: center;
  margin: 0;
}

.map-wrap {
  max-width: 90%;
  margin: auto;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .amenities-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    padding: 20px;
  }
}

.contact-p {
  padding: 20px;
}
