/* .container {
  position: relative;
} */

.navbar-container {
  z-index: 2;
}

main {
  /* width: 100vw;*/
  /* height: 20vh;  */
  background-color: transparent;
}
.btn01 {
  position: absolute;
  top: 5%;
  left: 10%;
  background: none;
  border: none;
  cursor: pointer;
}

.menu-icon {
  width: 24px;
  height: 24px;
  position: relative;
}

.menu-icon .line {
  display: block;
  width: 100%;
  height: 2px;
  background-color: black;
  position: absolute;
  transition: transform 0.3s ease;
}

.menu-icon .line:nth-child(1) {
  top: 0;
}

.menu-icon .line:nth-child(2) {
  top: 50%;
  transform: translateY(-50%);
}

.menu-icon .line:nth-child(3) {
  bottom: 0;
}

.active .line:nth-child(1),
.active .line:nth-child(3) {
  transform: rotate(45deg);
}

.active .line:nth-child(2) {
  opacity: 0;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.side-slide {
  background-color: #fff;
  top: 0;
  left: 0;
  height: 100%;
  width: 285px;
  position: fixed;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

.side-slide.visible {
  opacity: 1;
  position: absolute;
  pointer-events: auto;
  width: 50%;
  z-index: 3;
}
@media (max-width: 768px) {
  .side-slide {
    background-color: #fff;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    transition: opacity 0.3s ease;
    opacity: 0;
    pointer-events: none;
    /* border: green 2px solid; */
  }

  .side-slide.visible {
    opacity: 1;
    position: absolute;
    pointer-events: auto;
    width: 100%;
    height: 100%;
    z-index: 3;
  }
  .side-slide ul {
    list-style: none;
    margin-top: 70px;
    padding: 0;
    height: 37px;
  }

  .side-slide ul li {
    text-align: center;
    line-height: 35px;
    height: 115%;
  }
}

.side-slide ul {
  list-style: none;
  margin-top: 70px;
  padding: 0;
}

.side-slide ul li {
  text-align: center;
  line-height: 60px;
}

.side-slide ul li:hover {
  background-color: #f44646;
}

.side-slide ul li a {
  color: black;
  font-weight: bold;
  font-size: 14px;
}

nav.side-slide h3 {
  position: absolute;
  font-weight: bold;
  cursor: pointer;
  padding: 5px;
  color: #fff;
  top: 25px;
  right: 20px;
  transition: opacity 0.3s ease;
  opacity: 0;
  pointer-events: none;
}

nav.side-slide.visible h3 {
  opacity: 1;
  pointer-events: auto;
}
/* @media (max-width: 768px) {
  .menu-icon{
    display: none;
  }
} */
